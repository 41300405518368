<template>
  <div class="w-full border-l-2 border-dotted border-forrest mx-4 md:mx-8">
    <div class="w-full border-l-2 border-dotted border-forrest ml-1">
      <div :class="`nested-section ${item.fields.heading.toLowerCase().replace(/ /g, '-')} w-full grid grid-flow-row gap-2 border-l-2 border-dotted border-forrest ml-1`">
        <h2 class="font-thick text-3xl uppercase font-black text-turqoise row-span-1 px-4 md:px-8" v-text="item.fields.heading" />
        <div v-for="(mItem, mi) in item.fields.menuItems" :key="mi"
          :class="`w-full mb-auto animated fadeIn relative col-span-1 row-span-1`">
          <FoodMenuItem :item="mItem" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FoodMenuItem from '~/components/menu/FoodMenuItem.vue'

export default {
  name: 'FoodMenuNestedSection',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    FoodMenuItem
  }
}
</script>