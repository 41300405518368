<template>
    <div class="w-full bg-forrest absolute z-30 left-0 bottom-0 px-4 pb-2">
        <div :class="`w-full flex flex-wrap ${menuToggled ? 'pb-10' : ''}`">
            <div class="w-full text-center pt-3">
                <img class="w-full block h-16 mb-2"
                    src="//images.ctfassets.net/3dar4x4x74wk/p6nChfDEDzPVHd2WuHOvD/76860d9b41b5c7af032d1536cc3922df/family-kitchen-great-food-quality-time.svg"
                    alt="family-kitchen-great-food-quality-time">
            </div>
            <div v-if="!menuToggled" class="w-full flex items-center relative">
                <div @click="menuToggled = !menuToggled" class="w-5 h-4 relative flex flex-wrap items-center origin-center">
                    <div v-for="item, i in 3" :key="i"
                        :class="`w-full h-px bg-white  border border-white rounded-sm transform origin-center transition-all ease-out duration-100 ${menuToggled && i === 1 ? 'opacity-0 absolute' : ' '} ${menuToggled && i === 2 ? 'rotate-45 absolute translate-y-0' : ' '} ${menuToggled && i === 0 ? '-rotate-45 absolute translate-y-0' : ' '}`">
                    </div>
                </div>
                <div
                    class="font-balbeer font-normal text-white text-lg mt-1 ml-2 py-2 leading-none h-full flex items-center uppercase">
                    {{ cat.fields.navName || cat.fields.title }}
                </div>
            </div>
        </div>
        <transition name="collapse" @leave="leave" @enter="enter">
            <div v-if="menuToggled" :class="`w-full absolute left-0 bg-forrest h-screen overflow-hidden pb-4 pl-4`">
                <div class="w-full flex items-center text-white relative uppercase" v-for="(category, index) in links"
                    :key="category.index">
                    <div v-if="cat.fields.id === category.fields.id" @click="menuToggled = !menuToggled" class="w-5 h-4 flex flex-wrap items-center origin-center absolute left-0">
                        <div v-for="item, i in 3" :key="i"
                            :class="`w-full h-px bg-white  border border-white rounded-sm transform origin-center transition-all ease-out duration-100 ${menuToggled && i === 1 ? 'opacity-0 absolute' : ' '} ${menuToggled && i === 2 ? 'rotate-45 absolute translate-y-0' : ' '} ${menuToggled && i === 0 ? '-rotate-45 absolute translate-y-0' : ' '}`">
                        </div>
                    </div>
                    <a href="#" @click.prevent="showMobileMenu(index, category)"
                        class="w-full block px-8 py-2 text-lg leading-none font-balbeer animated fadeIn"
                        v-html="category.fields.title">

                    </a>
                </div>
            </div>
        </transition>


    </div>
</template>

<script>
export default {
    props: {
        cat: {
            type: Object,
            default: () => {
            }
        },
        content: {
            type: Object,
            default: () => {
            }
        },
    },
    data() {
        return {
            menuToggled: false,
            currentIndex: 0
        }
    },

    methods: {
        leave(element) {
            const height = getComputedStyle(element).height;
            element.style.height = height;
            getComputedStyle(element).height;
            setTimeout(() => {
                element.style.height = 0;
            });
        },
        enter(element) {
            const width = getComputedStyle(element).width;
            element.style.width = width;
            element.style.position = 'absolute';
            element.style.visibility = 'hidden';
            element.style.height = 'auto';
            const height = getComputedStyle(element).height;
            element.style.width = null;
            element.style.position = null;
            element.style.visibility = null;
            element.style.height = 0;
            getComputedStyle(element).height;
            setTimeout(() => {
                let rect = element.getBoundingClientRect();
                element.style.height = `calc(100vh - ${rect.top}px)`;
            });
        },

        showMobileMenu(index, category) {
            const catindex = this.unfilteredLinks.indexOf(category.fields.id)
            this.menuToggled = false
            this.$emit('showmenu', { catindex, category })
        },
    },

    computed: {
        links() {
            return this.content.children
            // return this.content.children.filter(l =>
            //     l.fields.id !== this.cat.fields.id
            // )
        },
        unfilteredLinks() {
            return this.content.children.map(c => c.fields.id)
        }
    },
}
</script>

<style>
.collapse-enter-active,
.collapse-leave-active {
    will-change: auto;
    transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);
}

.collapse-enter,
.collapse-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    /*   opacity: 0; */
    height: 0;
}
</style>