<template>
  <div class="food-menu relative flex bg-white">
    <mq-layout mq="lg+">
      <nav class="relative w-full pb-32 sidemenu md:w-64">
        <div class="w-full">
          <div class="p-4 mx-auto text-center">
            <img class="w-full h-32 object-contain object-center my-16"
              src="//images.ctfassets.net/3dar4x4x74wk/3kaIv9vZ8ZQtoIm0ZjIya9/185c563be4d75bb63ca5218d606cb682/Family_Kitchen_Logo.png"
              :alt="content.heading">
          </div>
        </div>
        <div class="w-full text-center text-turqoise uppercase" v-for="(category, index) in content.children"
          :key="category.index">
          <a href="#" @click.prevent="showMenu(index, category)"
            class="block w-full px-12 py-4 leading-none transition-all duration-200 ease-out font-kentp hover:bg-turqoise hover:text-white"
            :class="{ 'bg-forrest': index == currentIndex }">
            <div class="menu-item text-center mx-auto" v-html="category.fields.navName || category.fields.title"> </div>
          </a>
        </div>
      </nav>
    </mq-layout>
    <section id="menu-items" class="relative flex-1 w-full min-h-full bg-milk">
      <div class="w-full mb-4 menu-hero-container">
        <div v-if="currentCategory && currentCategory.fields"
          class="relative z-30 lg:overflow-hidden animated fadeIn ">

          <div v-if="currentCategory.fields.callOutText"
            class="absolute bottom-0 right-0 z-10 px-3 py-1 mb-32 bg-underbrush font-kentps rounded-l-2xl text-milk">
            {{
                currentCategory.fields.callOutText
            }}</div>
          <img class="cat-image hidden w-full lg:block" v-if="catImage && catImage.fields"
            :src="`${catImage.fields.file.url}?w=1800&h=900&fit=fill&fm=webp`" :alt="catImage.fields.title">
          <img class="cat-image w-full pb-10 lg:hidden md:pb-0" v-if="catImage && catImage.fields"
            :src="`${catImage.fields.file.url}?w=1800&h=900&fit=fill&fm=webp`" :alt="catImage.fields.title">
          <mq-layout :mq="['sm', 'md']" class="flex items-center w-full">
            <mobile-food-menu @showmenu="showMobileMenu" :cat="currentCategory" :content="content" />
          </mq-layout>
        </div>
      </div>
      <div v-for="(cat, c) in currentChildCats" :key="c"
        :class="`${cat.fields.heading.toLowerCase().replace(/ /g, '-')} bg-milk w-full grid lg:grid-cols-${cat.fields.colSpan === '3' || cat.fields.heading.toLowerCase() === 'shakes' || cat.fields.heading.toLowerCase() === 'sodas &'  ? `3 tripe-column` : 2} grid-flow-row ${cat.fields.gridPlacement ? cat.fields.gridPlacement.split(':').pop() : 'place-items-end'} gap-4 py-8`">
        <div
          :class="`w-full px-4 lg:pl-8 ${cat.fields.heading.toLowerCase() === 'sodas &' ? 'my-auto' : ''} animated fadeIn ${cat.fields.rowSpan ? `row-span-1 lg:row-span-${cat.fields.rowSpan}` : 'row-span-1'} ${cat.fields.colSpan ? `col-span-${cat.fields.colSpan}` : 'col-span-2'}`">
          <div class="w-full">
            <img :class="`${cat.fields.heading.toLowerCase() === 'kiddos' ? 'w-1/3' : 'w-full lg:w-2/3'} ${cat.fields.heading.toLowerCase() === 'shakes' ? 'ml-auto' : ''}`" v-if="cat.fields.lockup" :src="cat.fields.lockup.fields.file.url" :alt="cat.fields.heading">
            <h1
              v-else
              :class="`mb-2 ${cat.fields.heading.toLowerCase() === 'sodas &' ? 'text-center text-2xl lg:text-3xl' : 'text-4xl lg:text-5xl'} uppercase font-black font-decimal ${ cat.fields.heading.toLowerCase().includes('sweet') ? 'text-skyblue' : 'text-turqoise'} tracking-condensed leading-pressed`"
              v-text="cat.fields.heading">
            </h1>

            <h2 v-if="cat.fields.subHeading" :class="`mb-2 ${cat.fields.heading.toLowerCase() === 'sodas &' ? 'text-center text-2xl lg:text-3xl' : 'text-4xl lg:text-5xl'} uppercase font-black font-decimal text-underbrush tracking-condensed leading-pressed`">
              {{ cat.fields.subHeading }}</h2>

          </div>
          <div v-if="cat.fields.leftColOptions" class="flex flex-wrap w-full">
            <div class="w-full p-4 lg:pl-0 lg:w-1/2" v-if="cat.fields.leftColOptions">
              <div class="text-lg cat-text font-kentps lg:text-xl" v-if="cat.fields.leftColOptions"
                v-html="$md.render(cat.fields.leftColOptions)">

              </div>
            </div>
            <div class="w-full p-4 lg:pl-0 lg:w-1/2">
              <div class="text-lg cat-text font-kentps lg:text-xl" v-if="cat.fields.rightColOptions"
                v-html="$md.render(cat.fields.rightColOptions)">

              </div>
            </div>
          </div>
        </div>

        <div v-for="(item, i) in cat.fields.menuItems" :key="i"
          :class="`w-full mb-auto animated fadeIn relative ${item.fields.bundle ? 'col-span-2' : cat.fields.colSpan ? `col-span-${cat.fields.colSpan} md:col-span-1` : 'col-span-2 md:col-span-1'} ${item.fields.rowSpan ? `row-span-${item.fields.rowSpan}` : 'row-span-1'}`">
          <FoodMenuItem v-if="!item.fields.menuItems" :item="item" />
          <FoodMenuNestedSection v-else :item="item" />
        </div>
        <div v-if="cat.fields.emphasizedText"
          :class="`font-kentp text-sm leading-none inline-block align-middle py-2 px-4 text-${cat.fields.emphasizedTextColor}  rounded-2xl border-2 border-${cat.fields.emphasizedTextColor}`">
          <span> {{ cat.fields.emphasizedText }}</span>
        </div>
        <div :class="`featured-cat-image w-full max-w-screen overflow-hidden flex flex-col items-center justify-center pt-4 col-span-${cat.fields.colSpan ? cat.fields.colSpan : '2'}`" v-if="cat.fields.featuredImage">
          <img :src="cat.fields.featuredImage.fields.file.url" :alt="cat.fields.featuredImage.fields.title">
        </div>
      </div>
      <div class="flex flex-col xl:flex-row items-start lg:items-center gap-6 px-4 py-8 lg:px-12">
        <p class="font-decimal font-black uppercase text-xs text-underbrush">Gold = Featured Item</p>
        <div class="w-full lg:w-auto flex flex-col lg:flex-row items-center gap-4">
          <a class="w-full lg:w-auto px-4 py-2 font-decimal font-bold uppercase text-xs text-center border-2 border-seaworthy-green text-seaworthy-green rounded-3xl hover:bg-seaworthy-green hover:text-white"
            href="https://assets.ctfassets.net/3dar4x4x74wk/eo5pk1hlY1laRbUdaUlx2/04f70c6f45b9e3ed3b78403397bf1d37/ME___Nutrition_Menu.pdf"
            target="_blank">
            Nutritional Information
          </a>
          <a class="w-full lg:w-auto px-4 py-2 font-decimal font-bold uppercase text-xs text-center border-2 border-seaworthy-green text-seaworthy-green rounded-3xl hover:bg-seaworthy-green hover:text-white"
            href="https://assets.ctfassets.net/3dar4x4x74wk/3mcobWwkKZ084OEB5vpfwX/10ad9f4c0a45b8a99679f0dac4727e85/ME___Allergens_Menu.pdf"
            target="_blank">
            Allergens
          </a>
          <!-- <div @click="$store.commit('setLocationFinder', true)" class="w-full lg:w-auto curosor-pointer px-4 py-2 font-decimal font-bold uppercase text-xs text-center border-2 border-seaworthy-green text-seaworthy-green rounded-3xl hover:bg-seaworthy-green hover:text-white">Select another Location</div> -->
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import FoodMenuItem from './FoodMenuItem'
import FoodMenuNestedSection from './FoodMenuNestedSection'
import MenuItem from './MenuItem'
import MenuSectionMedia from './MenuSectionMedia'
import MobileFoodMenu from './MobileFoodMenu'

export default {
  components: {
    FoodMenuItem,
    FoodMenuNestedSection,
    MenuItem,
    MenuSectionMedia,
    MobileFoodMenu
  },
  props: [
    'content',
    'legal',
  ],
  data() {
    return {
      currentIndex: 0,
      currentCategory: {}
    }
  },
  methods: {
    showMobileMenu(params) {
      this.showMenu(params.catindex, params.category)
    },
    showMenu(index, category) {
      this.currentIndex = index
      this.currentCategory = category
    }
  },
  mounted() {
    this.currentCategory = this.content.children[0]
    console.log('test content: ', this.content)
  },
  computed: {
    featuredImage() {
      return true
    },
    childCats() {
      if (!this.content.children && !this.content.children.length) return ''
      return this.content.children.map(c => {
        return c.fields.menuSections
      })
    },
    currentChildCats() {
      return this.childCats[this.currentIndex]
    },
    catImage() {
      return this.currentCategory.fields.categoryImage
    }
  },
}

</script>

<style lang="scss" scoped>
.food-menu {
  .sidemenu {
    .menu-item {
      width: 100px;
      max-width: unset;
      min-width: unset;
    }
  }
}

.cat-image {
  height: 395px;
  object-fit: cover;
  object-position: left top;
}
</style>

<style lang="postcss">
.purge-a {
  @apply leading-loose leading-snug -translate-x-4 translate-x-4 -translate-x-8 translate-x-8 row-span-1 row-span-2 row-span-3 row-span-4 row-span-5 row-span-6 border-seaworthy-green border-underbrush col-span-1 col-span-2 col-span-3 col-span-4 col-span-5 text-milk;
}

.place-items-end {
  place-items: flex-end;
}

.place-items-center {
  place-items: center;
}

.options-text li {
  @apply leading-none mb-2;
}

.cat-text h3 {
  @apply text-underbrush inline-block text-base font-kentp py-1 mb-2 px-4 border-2 border-underbrush rounded-2xl -ml-4;
}

.bundle-item-opts h2 {
  @apply text-underbrush inline-block text-base font-kentp py-1 mb-4 px-4 border-2 border-underbrush rounded-2xl -ml-4;
}

.bundle-item-opts li {
  @apply leading-none mb-2;
}

.menu-item-desc {
  @apply text-underbrush;
}

.menu-item-desc p {
  @apply mb-1;

  &:last-child {
    @apply mb-0;
  }
}

.menu-item-desc-hightlight h2 {
  @apply text-2xl leading-none;
}

.menu-item-desc-hightlight h4 {
  @apply text-xl mb-2 leading-none;
}

.menu-item-desc-hightlight h5 {
  @apply text-base font-kentp leading-none;
}

.menu-item-desc-hightlight p {
  @apply font-kentp mb-0 pb-0 text-xs;
}

.menu-item-desc p {
  @apply text-underbrush pb-1;
}

.menu-item.featured-right {
  @apply translate-x-4 rounded-l-3xl;

  @screen lg {
    @apply translate-x-8;
  }

}

.menu-item.featured-left {
  @apply -translate-x-4 rounded-r-3xl;

  @screen lg {
    @apply -translate-x-8;
  }
}
</style>
