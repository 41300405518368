<template>
  <div v-if="item.fields.highlightText" class="food-menu-highlight flex flex-wrap items-center flex-1 w-full px-4 md:px-8 relative">
    <div
      :class="`font-decimal font-black text-sm ${ item.fields.featured ? 'text-scarlet' : 'text-forrest'}`">
      <span> {{ item.fields.highlightText }}</span>
    </div>
    <div v-if="item.fields.extraTitle || item.fields.extraDescription">
      <div class="spacer w-full h-1 mt-4 mb-1 border-dotted border-b-2 border-forrest" />
      <div class="spacer w-full h-1 my-1 border-dotted border-b-2 border-forrest" />
      <div class="spacer w-full h-1 mt-1 mb-4 border-dotted border-b-2 border-forrest" />
      <div class="border-l-8 border-underbrush pl-4">
        <h5 class="font-decimal font-black text-sm text-scarlet" v-if="item.fields.extraTitle" v-text="item.fields.extraTitle"/>
        <p class="font-decimal text-xs text-forrest" v-if="item.fields.extraDescription" v-html="item.fields.extraDescription" />
      </div>
    </div>
  </div>

  <div v-else-if="item.fields.imageOnly && item.fields.featured" :class="`featured-image-section relative w-full overflow-hidden px-4 md:px-8`">
    <img :class="`${item.fields.customClass ? item.fields.customClass : 'w-full'} h-full object-contain object-center`" :src="item.fields.image.fields.file.url" :alt="item.fields.image.fields.title">
  </div>
  
  <div v-else-if="item.fields.imageOnly" :class="`featured-image relative w-full overflow-hidden mx-4 md:mx-0`" :style="`height: ${85 * parseInt(item.fields.rowSpan)}px;`">
    <img :class="`w-full h-full flex items-center justify-center object-cover object-center ${item.fields.customClass ? item.fields.customClass : item.fields.featureAlignment === 'left' ? 'rounded-r-2xl' : 'rounded-l-2xl'}`" :src="item.fields.image.fields.file.url" :alt="item.fields.image.fields.title">
    <p v-if="item.fields.extraTitle" :class="`font-decimal text-sm font-black text-center text-white absolute bottom-0 ${item.fields.featureAlignment === 'left' ? 'right-0' : 'left-0'} p-4`" v-text="item.fields.extraTitle" />
  </div>

  <div v-else :class="`food-menu-item flex flex-wrap items-center flex-1 w-full px-4 md:px-8 relative`">
    <div :class="`w-full ${item.fields.showEmphasis ? 'flex-1' : ''}`">
      <h3 v-if="item.fields.title" :class="`leading-none uppercase font-decimal font-bold lg:text-lg ${item.fields.featured ? 'text-underbrush' : item.fields.customClass ? ` text-forrest ${item.fields.customClass}` : 'text-forrest'}`"
        v-html="item.fields.title"> </h3>
      <h3 v-if="item.fields.extraTtitle" :class="`leading-none font-italic uppercase font-decimal font-bold lg:text-lg ${item.fields.featured ? 'text-underbrush' : item.fields.customClass ? item.fields.customClass : 'text-forrest'}`"
        v-html="item.fields.extraTitle"> </h3>
      <div v-if="item.fields.description" :class=" `leading-none text-sm font-decimal mt-2 ${item.fields.featured ? 'text-underbrush' : 'text-black'}`"
        v-html="item.fields.description"></div>

      <div v-if="item.fields.extraDescription" v-html="item.fields.extraDescription"
        class="pt-2 text-sm leading-none font-decimal font-black text-forrest">
      </div>
      <h4 v-if="item.fields.optionsHeading" class="mt-4 mb-4 text-xl leading-none uppercase font-kentps">{{
          item.fields.optionsHeading
      }}</h4>
      <div v-if="item.fields.leftColOptions" class="flex flex-wrap w-full options-text">
        <div class="w-full uppercase lg:w-1/2 font-kentp" v-html="$md.render(item.fields.leftColOptions)"></div>
        <div v-if="item.fields.rightColOptions" class="w-full uppercase lg:w-1/2 font-kentp"
          v-html="$md.render(item.fields.rightColOptions)"></div>
      </div>
    </div>
    <!-- <img class="w-16 mr-4 cursor-pointer " v-if="item.fields.showEmphasis"
      @mouseover="$store.commit('setFoodModal', { toggled: true, content: item })"
      src="//images.ctfassets.net/3dar4x4x74wk/4RQZy8QNaLHPpP4GF5jBHx/1643f43bcd3e692939791dc9bca41a09/checkit-out-bubble.svg"
      alt="check it out"> -->
  </div>
</template>

<script>
export default {
  name: 'FoodMenuItem',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/_variables';

.featured-image {
  img {
    &:after {
      @apply absolute top-0 left-0 w-full h-full block;
      content: '';
      background-color: rgba($dark, 0.5);
      mix-blend-mode: multiply;
    }
  }
}
</style>